import { t } from 'i18next'
import React from 'react'
import styles from './Footer.module.scss'

export default function Footer() {
    return (
        <div className={styles.main}>
            <div className={styles.content}>
                <div className={styles['item-list']}>
                    <a href="/">{t('About GSKY')}</a>
                    <span>{" | "}</span>
                    <a href="/">{t('Contact GSKY')}</a>
                    <span>{" | "}</span>
                    <a href="/">{t('Membership Terms')}</a>
                    <span>{" | "}</span>
                    <a href="/">{t('Privacy Policy')}</a>
                </div>
                <div className={styles['item-list']}>
                    GSKY Co.,Ltd. All Rights Reserved.
                </div>
                <div className={styles['item-list']}>
                    {t('CustomerServiceHourDsc')}
                </div>
            </div>
        </div>
    )
}
