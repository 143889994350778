import React, {useRef, useState, useEffect} from 'react'
import styles from './FeaturedArea.module.scss'
import { useLocation, useNavigate, Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function FeaturedArea({titleName, productList, productType}) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const containerRef = useRef(null); // 使用ref来引用滚动容器
    const [hasHorizontalScrollbar, setHasHorizontalScrollbar] = useState(false);

    const handleLeftScroll = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 1000; // 向右滚动1000px
        }
    }

    const handleRightScroll = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 1000; // 向右滚动1000px
        }
    }

    const productDetailBtm = (productItem) => {
        navigate(`/ProductDetail/${productItem.id}`, {state:productItem})
    }

    useEffect(() => {
        //判斷出現卷軸才顯示arrow
        const element = containerRef.current;
        if (element) {
            const horizontal = element.scrollWidth > element.clientWidth;
            setHasHorizontalScrollbar(horizontal);
        }
    },[])


    return (
        <div className={styles.productBlock}>
            <div className={styles.title}>{t(titleName)}</div>
            <hr className={styles.line}></hr>
            {
                hasHorizontalScrollbar &&
                <div className={styles.arrow} style={{left:'20px'}}>
                    <div className={styles['arrow-block']} onClick={handleLeftScroll}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                </div>
            }
            <div className={styles["block-content"]} ref={containerRef}>
                {
                    productList
                        .filter(productItem => productItem.productType === productType)
                        .map(productItem => (
                            <div className={styles['block-content-item']} key={productItem.id} onClick={() => productDetailBtm(productItem)}>
                                <div className={styles.itemImg}>
                                    <img src={`${process.env.PUBLIC_URL}/img/product/${productItem.productImg}`}/>
                                </div>
                                <span className={styles.itemName}>{t(productItem.productName)}</span>
                                <span className={styles.itemPrice}>${productItem.productPrice}</span>
                            </div>
                        ))
                }
            </div>
            {
                hasHorizontalScrollbar &&
                <div className={styles.arrow} style={{right:'20px'}}>
                    <div className={styles['arrow-block']} onClick={handleRightScroll}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                </div>
                }
        </div>
    )
}
