import React from 'react'
import styles from './Marquee.module.scss'

export default function Marquee(props) {
    return (
        <div className={styles.marquee}>
            <div className={styles['marquee-content']}>
                {props.text}
            </div>
        </div>
    );
}
