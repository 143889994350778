import { t } from 'i18next';
import React, {useState, useEffect, useContext, useRef } from 'react'
import Footer from '../../Component/Footer/Footer';
import styles from './Home.module.scss'
import { useNavigate } from 'react-router-dom'
import { CategoryContext, ProductContext} from '../../Context/GlobalContext'
import { useTranslation } from 'react-i18next';
import FeaturedArea from '../../Component/FeaturedArea/FeaturedArea';

export default function Home() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {categoryList} = useContext(CategoryContext)
    const [newsData, setNewsData] = useState({id:1, img:"new1.png"})
    const {productList, setProductList} = useContext(ProductContext)

    const news = [
        {id:1, img:"new1.png"},
        {id:2, img:"new2.png"},
        {id:3, img:"new3.png"},
        {id:4, img:"new4.png"},
        {id:5, img:"new5.png"},
        {id:6, img:"new6.png"}
    ]

    // const categoryList = [
    //     {id:1, name:'Keyboard'},
    //     {id:2, name:'Mouse'},
    //     {id:3, name:'Headset'},
    //     {id:4, name:'Fan'},
    //     {id:5, name:'Keycaps'},
    //     {id:6, name:'Other'}
    // ]

    const categoryBtn = (categoryItem) => {
        navigate(`/ProductList/${categoryItem.name}`, { state: categoryItem });
    }

    let newIndex = 0;

    useEffect(() => {
        setInterval(() => {
            setNewsData(news[newIndex])
            newIndex++;
            if(newIndex > 5)
                newIndex = 0;
        },5000)
    },[])

    return (
        <>
            <div className={styles.main}>
                <div className={styles['main-content']}>
                    {/* <img className={styles.backgroundImg} src={process.env.PUBLIC_URL + '/img/background.png'} /> */}
                    <div className={styles.content}>
                        <div className={styles['content-header']}>
                            <div className={styles.news}>
                                {
                                    (newsData.img != undefined) &&
                                    <img src={`${process.env.PUBLIC_URL}/img/news/${newsData.img}`}/>
                                }
                            </div>
                            <div className={styles.ad}>
                                <div className={styles['ad-img']}>
                                    <img src={process.env.PUBLIC_URL + '/img/news/dollar_ad.png'}/>
                                </div>
                                <div className={styles['ad-img']}>
                                    <img src={process.env.PUBLIC_URL + '/img/news/1500_dollar.png'}/>
                                </div>
                                <div className={styles['ad-img']}>
                                    <img src={process.env.PUBLIC_URL + '/img/news/1000_dollar.png'}/>
                                </div>
                                <div className={styles['ad-img']}>
                                    <img src={process.env.PUBLIC_URL + '/img/news/500_dollar.png'}/>
                                </div>
                            </div>
                            <div className={styles.categoryList}>
                                <div className={styles.categoryTitle}> {t('Product List')} </div>
                                {
                                    categoryList.map(categoryItem => (
                                        <div className={styles.categoryItem} key={categoryItem.id} onClick={() => categoryBtn(categoryItem)}> {t(categoryItem.name)} </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={styles.productList}>
                            <FeaturedArea  titleName={'Keyboard'} productList={productList} productType={2}/>
                            <FeaturedArea  titleName={'Mouse'} productList={productList} productType={1}/>
                            <FeaturedArea  titleName={'Fan'} productList={productList} productType={4}/>
                            <FeaturedArea  titleName={'Keycaps'} productList={productList} productType={5}/>
                            <FeaturedArea  titleName={'Others'} productList={productList} productType={6}/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}
