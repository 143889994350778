import React, {useContext, useEffect, useState} from 'react';
import Footer from '../../Component/Footer/Footer';
import { useNavigate, Link} from 'react-router-dom'
import { CategoryContext, CartShoppingContext, UserContext} from '../../Context/GlobalContext';
import styles from './ShoppingCart.module.scss'
import { useTranslation } from 'react-i18next';
import QuantityBtn from '../../Component/QuantityBtn/QuantityBtn';

export default function ShoppingCart() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {shoppingCartItem, setShoppingCartItem} = useContext(CartShoppingContext)
    const {userData, setUserData} = useContext(UserContext)
    const [totalPrice, setTotalPrice] = useState(0)
    useEffect(() => {
        console.log(shoppingCartItem)
    },[])

    useEffect(() => {
        if(!userData.login)
            navigate('/UserProfile')
    },[userData])

    useEffect(() => {
        let total= 0;
        shoppingCartItem.map(item => total += item.productPrice * item.number);
        setTotalPrice(total)
    },[shoppingCartItem])

    return (
        <div className={styles.main}>
            {
                (shoppingCartItem.length > 0) ?
                <div className={styles.content}>
                    <div className={styles['cart-Content']}>
                        <div className={styles['ListContent']}>
                            <div className={styles['title']}>{t('Shopping Cart')}</div>
                            {
                                shoppingCartItem.map(item => (
                                    <div className={styles.cartItem} key={item.id}>
                                        <div className={styles['cartItem-content']}>
                                            <div className={styles['product-img']}>
                                                <img src={`${process.env.PUBLIC_URL}/img/product/${item.productImg}`}/>
                                            </div>
                                            <div className={styles['product-detail']}>
                                                <div className={styles['product-name']}>
                                                    {item.productName}
                                                </div>
                                                <div className={styles['product-namber']}>
                                                    <div className={styles.quantity}>
                                                        <QuantityBtn ProductDetail={item}/>
                                                    </div>
                                                </div>
                                                <div className={styles['product-price']}>
                                                    ${item.productPrice}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className={styles['total-price']}>
                                <span className={styles["priceText"]}>{t('Total product amount')}</span>
                                <span className={styles.price}>${totalPrice}</span>
                                <Link className={styles.checkout} to="/CheckOut">{t('check out')}</Link>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                :
                <div className={styles['empty-Content']}>
                    <div className={styles['cart-Empty-Content']}>
                        <div className={styles[`empty`]}>
                            <img src={`${process.env.PUBLIC_URL}/img/shoppingCartEmpty.webp`}></img>
                            <span className={'empty-text'}>{t('CartShoppingEmptyDsc')}</span>
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </div>
    )
}
