import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SignUp.module.scss'
import { UserContext } from '../../Context/GlobalContext'
import { useNavigate, Link } from 'react-router-dom'
import Footer from '../../Component/Footer/Footer';

export default function SingUp() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const {userData, setUserData} = useContext(UserContext)
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [submitFlag, setSubmitFlag] = useState(false)
    const [validateEmailFlag, setValidateEmailFlag] = useState(false)

    const handleUserNameChange = (e) => {
        setSubmitFlag(false)
        setUserName(e.target.value)
    }

    const handlePasswordChange = (e) => {
        setSubmitFlag(false)
        setUserPassword(e.target.value)
    }

    const handleEmailChange = (e) => {
        setSubmitFlag(false)
        setEmail(e.target.value)
    }

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value)
    }

    const handleAddressChange = (e) => {
        setAddress(e.target.value)
    }

    const submitBtn = () => {
        setSubmitFlag(true)
        if(userName == '' || email == '' || userPassword == '')
            return;
    }

    useEffect(() => {
        //驗證email 格式
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setValidateEmailFlag( re.test(String(email).toLowerCase()))
    },[email])

    return (
        <>
            <div className={styles.main}>
                <div className={styles.content}>
                    <div className={styles['content-detail']}>
                        <div className={styles.creatAccount}>
                            <div className={styles.logo}>{t('G-Shop Create Account')}</div>
                            <div className={styles.signupContent}>
                                <div className={styles.logoTitle}>
                                    {t('Create Account')}
                                </div>
                                <div className={styles.creatAccount_signupItem}>
                                    <label className={styles.text}>{t('UserName')}
                                    {
                                        (submitFlag && userName.length == 0) &&
                                        <div className={styles.alert}>
                                            *{t('Required')}
                                        </div>
                                    }
                                    </label>
                                    <input type="text" className={styles.input} onChange={handleUserNameChange} tabIndex={1}/>
                                </div>
                                <div className={styles.creatAccount_signupItem}>
                                    <label className={styles.text}>{t('Email')}
                                    {
                                        (submitFlag && email.length == 0) ?
                                        <div className={styles.alert}>
                                            *{t('Required')}
                                        </div>
                                        : (submitFlag && !validateEmailFlag) ?
                                        <div className={styles.alert}>
                                            *{t('Error mail')}
                                        </div>
                                        :
                                        <div></div>
                                    }
                                    </label>
                                    <input type='email' className={styles.input} onChange={handleEmailChange} tabIndex={2}/>
                                </div>
                                <div className={styles.creatAccount_signupItem}>
                                    <label className={styles.text}>{t('Password')}
                                    {
                                        (submitFlag && userPassword.length == 0) &&
                                        <div className={styles.alert}>
                                            *{t('Required')}
                                        </div>
                                    }
                                    </label>
                                    <input type="password" className={styles.input} onChange={handlePasswordChange} tabIndex={3}/>
                                </div>
                                <div className={styles.creatAccount_signupItem}>
                                    <label className={styles.text}>{t('Phone Number')}</label>
                                    <input type="number" className={styles.input} onChange={handlePhoneNumberChange} tabIndex={4}/>
                                </div>
                                <div className={styles.creatAccount_signupItem}>
                                    <label className={styles.text}>{t('Address')}</label>
                                    <input type="text" className={styles.input} onChange={handleAddressChange} tabIndex={5}/>
                                </div>
                                <div className={styles.creatAccount_signupItem} style={{marginTop:'40px', justifyContent:'center'}}>
                                    <button className={styles.signup} onClick={submitBtn} tabIndex={6}>{t('Submit')}</button>
                                </div>
                                <div className={styles.creatAccount_signupItem}>
                                    <hr className={styles.line}/>
                                </div>
                                <div className={styles.creatAccount_signupItem}>
                                    <label className={styles.text} style={{width:'100%', fontWeight:200, flexDirection:'row', justifyContent:"flex-start"}}>{t('Already have an account')}?
                                        <Link className={styles.link} to="/UserProfile" tabIndex={7}>{t('Sign in')}</Link>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </>
    )
}
