import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
.use(HttpApi) // 使用 http backend
.use(LanguageDetector) // 使用语言检测
.use(initReactI18next) // 通过 react-i18next 将 i18next 传递给 React
.init({
    supportedLngs: ['en', 'cn', 'tw'],
    supportedLangImg: ['English.svg','Chinese-Simple.svg','Chinese-Traditional.svg'],
    fallbackLng: "en",
    detection: {
        order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
        caches: ['cookie'],
        },
    backend: {
    loadPath: '/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
});

export default i18n;
