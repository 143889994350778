import React from 'react'
import styles from './AlertDialog.module.scss'
import { useTranslation } from 'react-i18next';

export default function AlertDialog({show, onClose, children}) {
    const { t } = useTranslation();
    if (!show)
        return null;

    return (
        <div className={styles.main}>
            <div className={styles['main-content']} onClick={e => e.stopPropagation()}>
                <span className={styles.text}>{children}</span>
                <button onClick={onClose}>{t('OK')}</button>
            </div>
        </div>
    )
}
