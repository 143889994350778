import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import Header from './Component/Header/Header';
import { UserContext, CartShoppingContext, CategoryContext, ProductContext} from './Context/GlobalContext'
import { useState } from 'react';
import Home from './Page/Home/Home';
import PorductList from './Page/ProductList/PorductList';
import UserProfile from './Page/UserProfile/UserProfile';
import SingUp from './Page/SignUp/SingUp';
import ForgetPassword from './Page/ForgetPassword/ForgetPassword';
import ProductDetail from './Page/ProductDetail/ProductDetail';
import ShoppingCart from './Page/ShoppingCart/ShoppingCart';
import CheckOut from './Page/CheckOut/CheckOut';

function App() {

	const [userData, setUserData] = useState({
		name: 'Guest',
		login: true,
		// language: 'en',
		email: 'guest@gmail.com'
	});
	const [shoppingCartItem, setShoppingCartItem] = useState([]);
	const [categoryList, setCategoryList] = useState([
		{id:1, name:'Mouse'},
        {id:2, name:'Keyboard'},
        {id:3, name:'Headset'},
        {id:4, name:'Fan'},
        {id:5, name:'Keycaps'},
        {id:6, name:'Others'}
	])

	const [productList, setProductList] = useState([
        {
            id:1,
            productImg: "ModelO-Wireless.png",
            productName: "Model O Wireless",
            productPrice: 1500,
            productLabels: [1,2,3],
			productType: 1,
            inventory: 10
        },
        {
            id:2,
            productImg: "GMMK Pro.webp",
            productName: "GMMK PRO",
            productPrice: 3500,
            productLabels: [1,2,3],
			productType: 2,
            inventory: 15
        },
        {
            id:3,
            productImg: "GW-104.png",
            productName: "GW-104",
            productPrice: 2000,
            productLabels: [1,2,3],
			productType: 2,
            inventory: 2
        },
        {
            id:4,
            productImg: "VENTO 120 ARGB PWM.png",
            productName: "VENTO 120 ARGB PWM",
            productPrice: 1200,
            productLabels: [1,2,3],
			productType: 4,
            inventory: 5
        },
        {
            id:5,
            productImg: "500_dollar.png",
            productName: "500金幣",
            productPrice: 500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 99
        },
        {
            id:6,
            productImg: "1000_dollar.png",
            productName: "1000金幣",
            productPrice: 500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 12
        },
        {
            id:7,
            productImg: "1500_dollar.png",
            productName: "1500金幣",
            productPrice: 1500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 8
        },
        {
            id:8,
            productImg: "500_dollar.png",
            productName: "500金幣",
            productPrice: 500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 1
        },
        {
            id:9,
            productImg: "1000_dollar.png",
            productName: "1000金幣",
            productPrice: 500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 4
        },
        {
            id:10,
            productImg: "1500_dollar.png",
            productName: "1500金幣",
            productPrice: 1500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 29
        },
        {
            id:11,
            productImg: "500_dollar.png",
            productName: "500金幣",
            productPrice: 500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 12
        },
        {
            id:12,
            productImg: "1000_dollar.png",
            productName: "1000金幣",
            productPrice: 500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 12
        },
        {
            id:13,
            productImg: "1500_dollar.png",
            productName: "1500金幣",
            productPrice: 1500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 134
        },
        {
            id:14,
            productImg: "500_dollar.png",
            productName: "500金幣",
            productPrice: 500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 1
        },
        {
            id:15,
            productImg: "1000_dollar.png",
            productName: "1000金幣",
            productPrice: 500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 2
        },
        {
            id:16,
            productImg: "1500_dollar.png",
            productName: "1500金幣",
            productPrice: 1500,
            productLabels: [1,2,3],
			productType: 6,
            inventory: 30
        }
    ]);


	return (
		<BrowserRouter>
			<UserContext.Provider value={{userData, setUserData}}>
			<CartShoppingContext.Provider value={{shoppingCartItem, setShoppingCartItem}}>
			<CategoryContext.Provider value={{categoryList, setCategoryList}}>
			<ProductContext.Provider value={{productList, setProductList}}>
				<Header/>
				<Routes>
					<Route path="/" element={<Home/>}/>
					<Route path="/ProductList" element={<PorductList/>}>
						<Route path=":id" element={<PorductList/>}/>
					</Route>
					<Route path="/UserProfile" element={<UserProfile/>} />
					<Route path="/SignUp" element={<SingUp/>} />
					<Route path="/ForgetPassword" element={<ForgetPassword/>} />
					<Route path="/ProductDetail" element={<ProductDetail/>}>
						<Route path=":id" element={<ProductDetail/>}/>
					</Route>
					<Route path="/ShoppingCart" element={<ShoppingCart/>}/>
					<Route path="/CheckOut" element={<CheckOut/>} />
					<Route path="*" element={<Home/>}/>
				</Routes>
			</ProductContext.Provider>
			</CategoryContext.Provider>
			</CartShoppingContext.Provider>
			</UserContext.Provider>
		</BrowserRouter>
	);
}

export default App;
