import React, {useState, useEffect} from 'react'
import styles from './ForgetPassword.module.scss'
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../Context/GlobalContext'
import { useNavigate } from 'react-router-dom'

export default function ForgetPassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('')
    const [submitFlag, setSubmitFlag] = useState(false)
    const [validateEmailFlag, setValidateEmailFlag] = useState(false)

    const handleEmailChange = (e) => {
        setSubmitFlag(false)
        setEmail(e.target.value)
    }

    const submitBtn = () => {
        setSubmitFlag(true)
    }

    useEffect(() => {
        //驗證email 格式
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setValidateEmailFlag( re.test(String(email).toLowerCase()))
    },[email])

    return (
        <div className={styles.main}>
            <div className={styles.creatAccount}>
                <div className={styles.logo}>{t('G-Shop')}</div>
                <div className={styles.forgetpasswordContent}>
                    <div className={styles.logoTitle}>
                        {t('Forget Password')}
                    </div>
                    <div className={styles.forgetItem}>
                        <label className={styles.text}>{t('Email')}
                        {
                            (submitFlag && email.length == 0) ?
                            <div className={styles.alert}>
                                *{t('Required')}
                            </div>
                            : (submitFlag && !validateEmailFlag) ?
                            <div className={styles.alert}>
                                *{t('Error mail')}
                            </div>
                            :
                            <div></div>
                        }
                        </label>
                        <input type="email" className={styles.input} onChange={handleEmailChange} tabIndex={1}/>
                    </div>
                    <div className={styles.forgetItem} style={{marginTop:'25px'}}>
                        <button onClick={submitBtn}>{t('Submit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
