import React, {useContext, useState, useEffect} from 'react'
import styles from './CheckOut.module.scss'
import { CategoryContext, CartShoppingContext, UserContext} from '../../Context/GlobalContext';
import { useTranslation } from 'react-i18next';
import Footer from '../../Component/Footer/Footer';
import Dropdown from '../../Component/Dropdown/Dropdown'
import AlertDialog from '../../Component/AlertDialog/AlertDialog';

export default function CheckOut() {
    const { t } = useTranslation();
    const {shoppingCartItem, setShoppingCartItem} = useContext(CartShoppingContext)
    const {userData, setUserData} = useContext(UserContext)
    const [paymentMethod, setpaymentMethod] = useState('0')
    const [creditCardNumber1, setCreditCardNumber1] = useState('');
    const [creditCardNumber2, setCreditCardNumber2] = useState('');
    const [creditCardNumber3, setCreditCardNumber3] = useState('');
    const [creditCardNumber4, setCreditCardNumber4] = useState('');
    const [creditCardMonth, setCreditCardMonth] = useState('');
    const [creditCardYear, setCreditCardYear] = useState('');
    const [month, setMonth] = useState([])
    const [year, setYear] = useState([])
    const [securityCode, setSecurityCode] = useState('')
    const [installmentMethod, setInstallmentMethod] = useState('0');
    const [showDialog, setShowDialog] = useState(false)

    useEffect(() => {
        const month= [];
        for(let i = 1; i <= 31; i++) {
            let obj = {
                label: i,
                value: i
            }
            month.push(obj)
        }
        setMonth(month)

        const year= [];
        for(let i = 0; i <= 31; i++) {
            let obj = {
                label: 2024 + i,
                value: 2024 + i
            }
            year.push(obj)
        }
        setYear(year)
    },[])


    const handlePaymentMethod = (event) => {
        setpaymentMethod(event.target.value)
    }

    const handleCreditCardNumber = (value, flag) => {
        value = value.replace(/[^\d]/g, '');
        if(flag === 1)
            setCreditCardNumber1(value)
        else if(flag === 2)
            setCreditCardNumber2(value)
        else if(flag === 3)
            setCreditCardNumber3(value)
        else if(flag === 4)
            setCreditCardNumber4(value)
    }

    const handleSecurityCode = (value) => {
        value = value.replace(/[^\d]/g, '');
        setSecurityCode(value);
    }

    const handleCreditCardMonthDropdownSelect = (value) => {
        setCreditCardMonth(value)
    }

    const handleCreditCardYesrDropdownSelect = (value) => {
        setCreditCardYear(value)
    }

    const handleInstallmentMethod = (event) => {
        setInstallmentMethod(event.target.value)
    }

    const handleCheckout = () => {
        if(creditCardNumber1 == '' || creditCardNumber2 == '' || creditCardNumber3 == '' || creditCardNumber4 == '' || securityCode == '')
            return setShowDialog(true)
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    }

    const paymentHtml = () => {
        if(paymentMethod === '0' || paymentMethod === '2')
            return (
                <div className={styles["block-item"]}>
                    <div className={styles['item-detail']}>
                        <span>{t('Credit card number')}:</span>
                        <input className={styles.Creditcard_number} type="text" maxLength="4" pattern="\d*" autoComplete="off" value={creditCardNumber1} onChange={(e) => handleCreditCardNumber(e.target.value, 1)}/>
                        <input className={styles.Creditcard_number} type="password" maxLength="4" pattern="\d*" autoComplete="off" value={creditCardNumber2} onChange={(e) => handleCreditCardNumber(e.target.value, 2)}/>
                        <input className={styles.Creditcard_number} type="text" maxLength="4" pattern="\d*" autoComplete="off" value={creditCardNumber3} onChange={(e) => handleCreditCardNumber(e.target.value, 3)}/>
                        <input className={styles.Creditcard_number} type="password" maxLength="4" pattern="\d*" autoComplete="off" value={creditCardNumber4} onChange={(e) => handleCreditCardNumber(e.target.value, 4)}/>
                    </div>
                    <div className={styles['item-detail']}>
                        <span>{t('Valid date')}:</span>
                        <Dropdown options={month} className={styles.dropdown} onSelect={() => handleCreditCardMonthDropdownSelect}/>
                        <Dropdown options={year} className={styles.dropdown} onSelect={() => handleCreditCardYesrDropdownSelect}/>
                        <input className={styles.Creditcard_number} type="password" placeholder={t('Security Code')} maxLength="3" pattern="\d*" autoComplete="off" value={securityCode} onChange={(e) => handleSecurityCode(e.target.value)}/>
                    </div>
                </div>
            )

        if(paymentMethod === '1')
            return (
                <>
                    <div className={styles["block-item"]}>
                        <div className={styles['item-detail']}>
                            <input type="radio" name="installment" checked={installmentMethod  === '0'} value="0" onChange={handleInstallmentMethod}/>
                            <span>{t('6M')}</span>
                        </div>
                        <div className={styles['item-detail']}>
                            <input type="radio" name="installment" checked={installmentMethod  === '1'} value="1" onChange={handleInstallmentMethod}/>
                            <span>{t('12M')}</span>
                        </div>
                        <div className={styles['item-detail']}>
                            <input type="radio" name="installment" checked={installmentMethod  === '2'} value="2" onChange={handleInstallmentMethod}/>
                            <span>{t('24M')}</span>
                        </div>
                    </div>
                    <div className={styles["block-item"]}>
                        <div className={styles['item-detail']}>
                            <span>{t('Credit card number')}:</span>
                            <input className={styles.Creditcard_number} type="text" maxLength="4" pattern="\d*" autoComplete="off" value={creditCardNumber1} onChange={(e) => handleCreditCardNumber(e.target.value, 1)}/>
                            <input className={styles.Creditcard_number} type="password" maxLength="4" pattern="\d*" autoComplete="off" value={creditCardNumber2} onChange={(e) => handleCreditCardNumber(e.target.value, 2)}/>
                            <input className={styles.Creditcard_number} type="text" maxLength="4" pattern="\d*" autoComplete="off" value={creditCardNumber3} onChange={(e) => handleCreditCardNumber(e.target.value, 3)}/>
                            <input className={styles.Creditcard_number} type="password" maxLength="4" pattern="\d*" autoComplete="off" value={creditCardNumber4} onChange={(e) => handleCreditCardNumber(e.target.value, 4)}/>
                        </div>
                        <div className={styles['item-detail']}>
                            <span>{t('Valid date')}:</span>
                            <Dropdown options={month} className={styles.dropdown} onSelect={() => handleCreditCardMonthDropdownSelect}/>
                            <Dropdown options={year} className={styles.dropdown} onSelect={() => handleCreditCardYesrDropdownSelect}/>
                            <input className={styles.Creditcard_number} type="password" placeholder={t('Security Code')} maxLength="3" pattern="\d*" autoComplete="off" value={securityCode} onChange={(e) => handleSecurityCode(e.target.value)}/>
                        </div>
                    </div>
                </>
            )
    }

    return (
        <>
            <AlertDialog show={showDialog} onClose={handleCloseDialog}>
                <span className={styles.dialogContent}>{t('Please confirm whether the information is correct')}</span>
            </AlertDialog>
            <div className={styles.main}>
                {/** 訂單相關 */}
                <div className={styles.content}>
                    <div className={styles.title}> {t('Order Information')} </div>
                    <table className={styles.table} cellSpacing="0" cellPadding="0">
                        <tbody className={styles.tbody}>
                            <tr>
                                <th>
                                    {t('UserName')}:
                                </th>
                                <td>
                                    {userData.name}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t('Email')}:
                                </th>
                                <td>
                                    {userData.email}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t('Address')}:
                                </th>
                                <td>
                                    {userData.email}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    {t('Phone Number')}:
                                </th>
                                <td>
                                    {userData.email}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={styles.title}> {t('Choose a payment method')} </div>
                    <div className={styles.block}>
                        <div className={styles["block-item"]}>
                            <div className={styles['item-detail']}>
                                <input type="radio" name="payment" checked={paymentMethod  === '0'} value="0" onChange={handlePaymentMethod}/>
                                <span>{t('Credit card')}</span>
                            </div>
                            <div className={styles['item-detail']}>
                                <input type="radio" name="payment" checked={paymentMethod  === '1'} value="1" onChange={handlePaymentMethod}/>
                                <span>{t('Credit card installments')}</span>
                            </div>
                            <div className={styles['item-detail']}>
                                <input type="radio" name="payment" checked={paymentMethod  === '2'} value="2" onChange={handlePaymentMethod}/>
                                <span>{t('Credit card bonus discount')}</span>
                            </div>
                        </div>
                        {paymentHtml()}
                        <div className={styles["block-item"]} style={{justifyContent:"center"}}>
                            <div className={styles['item-detail']} style={{height: '100px', alignItems:'flex-end'}}>
                                <button className={styles.checkout} onClick={handleCheckout}>{t('Checkout')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}
