import React, {useContext, useEffect, useState} from 'react';
import styles from './UserProfile.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faFileInvoiceDollar, faMapLocationDot, faShieldHalved, faListCheck, faPersonMilitaryPointing, faCreditCard} from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../../Context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Component/Footer/Footer';

export default function UserProfile() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {userData, setUserData} = useContext(UserContext)
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [currentPage, setCurrentPage] = useState('loginPage')
    const [userPassword, setUserPassword] = useState('')
    const [loginFlag, setLoginFlag] = useState(false)

    const handleUserNameChange = (e) => {
        setUserName(e.target.value)
    }

    const handlePasswordChange = (e) => {
        setUserPassword(e.target.value)
    }

    const loginBtn = () => {
        setLoginFlag(true)
        if(userName == '' || userPassword == '')
            return;
        let newUserData = {...userData, name:userName, login:true}
        setUserData(newUserData);
    }


    return (
        <>
            {
                // 判斷帳號是Guest則切到登入頁面
                (userData.name == undefined || (userData.name.toLowerCase() === 'guest' || userData === '')) ?
                <div className={styles.main}>
                    <div className={styles.content}>
                    <div className={styles['content-detail']}>
                            <div className={styles.loginMain}>
                                <div className={styles.logo}>{t('G-Shop Account')}</div>
                                <div className={styles.loginContent}>
                                    <div className={styles.logoTitle}>
                                        {t('Sign in')}
                                    </div>
                                    <div className={styles.loginItem}>
                                        <label className={styles.text}>{t('UserName')}
                                            {
                                                (loginFlag && userName.length == 0) &&
                                                <div className={styles.alert}>
                                                    *{t('Required')}
                                                </div>
                                            }
                                        </label>
                                        <input className={styles.input} onChange={handleUserNameChange}/>
                                    </div>
                                    <div className={styles.loginItem}>
                                        <label className={styles.text}>{t('Password')}
                                            {
                                                (loginFlag && userPassword.length == 0) &&
                                                <div className={styles.alert}>
                                                    *{t('Required')}
                                                </div>
                                            }</label>
                                        <input type="password" className={styles.input} onChange={handlePasswordChange}/>
                                    </div>
                                    <div className={styles.loginItem} style={{flexDirection:'row'}}>
                                        <button className={styles.login} onClick={loginBtn}>{t('Log in')}</button>
                                        <label className={styles.forgetPassword} onClick={() => navigate('/ForgetPassword')}>{t('forget the password')}?</label>
                                    </div>
                                    <div className={styles.loginItem}>
                                        <hr className={styles.line}/>
                                        <div className={styles.new}>
                                            <label>{t('New to G-Shop')}</label>
                                        </div>
                                    </div>
                                    <div className={styles.loginItem} style={{ alignItems:'center' }}>
                                        <button className={`${styles.login} ${styles.signup}`} onClick={() => navigate('/SignUp')}>{t('Sign up')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
                :
                // 有資料則顯示設定頁面
                <div className={styles.main}>
                    <div className={styles.content}>
                        <div className={styles['content-detail']} style={{alignItems:'flex-start'}}>
                            <div className={styles['profile-content']}>
                                <div className={styles.title}>{t('Your Account')}</div>
                                <div className={styles['content-table']}>
                                    <div className={styles['content-item']}>
                                        <div className={styles['item-img']} style={{color:'rgb(155,0,120)'}}>
                                            <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                        </div>
                                        <div className={styles.text}>
                                            <div className={styles['item-title']}>{t('Orders')}</div>
                                            <div className={styles['item-dsc']}>{t('OtherDsc')}</div>
                                        </div>
                                    </div>
                                    <div className={styles['content-item']}>
                                        <div className={styles['item-img']} style={{color:'rgb(0,124,0)'}}>
                                            <FontAwesomeIcon icon={faMapLocationDot} />
                                        </div>
                                        <div className={styles.text}>
                                            <div className={styles['item-title']}>{t('Address')}</div>
                                            <div className={styles['item-dsc']}>{t('AddressDsc')}</div>
                                        </div>
                                    </div>
                                    <div className={styles['content-item']}>
                                        <div className={styles['item-img']} style={{color:'rgb(0,124,255)'}}>
                                            <FontAwesomeIcon icon={faShieldHalved} />
                                        </div>
                                        <div className={styles.text}>
                                            <div className={styles['item-title']}>{t('Security')}</div>
                                            <div className={styles['item-dsc']}>{t('SecurityDsc')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles['content-table']}>
                                    <div className={styles['content-item']}>
                                        <div className={styles['item-img']} style={{color:'rgb(245,180,0)'}}>
                                            <FontAwesomeIcon icon={faCreditCard} />
                                        </div>
                                        <div className={styles.text}>
                                            <div className={styles['item-title']}>{t('Payments')}</div>
                                            <div className={styles['item-dsc']}>{t('PaymentsDsc')}</div>
                                        </div>
                                    </div>
                                    <div className={styles['content-item']}>
                                        <div className={styles['item-img']} style={{color:'rgb(150, 150, 150)'}}>
                                            <FontAwesomeIcon icon={faListCheck} />
                                        </div>
                                        <div className={styles.text}>
                                            <div className={styles['item-title']}>{t('Lists')}</div>
                                            <div className={styles['item-dsc']}>{t('ListsDsc')}</div>
                                        </div>
                                    </div>
                                    <div className={styles['content-item']}>
                                        <div className={styles['item-img']} style={{color:'rgb(255, 134, 0)'}}>
                                            <FontAwesomeIcon icon={faPersonMilitaryPointing} />
                                        </div>
                                        <div className={styles.text}>
                                            <div className={styles['item-title']}>{t('CustomerService')}</div>
                                            <div className={styles['item-dsc']}>{t('CustomerServiceDsc')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
            }
        </>
    )
}
