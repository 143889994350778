import React, {useState, useContext, useEffect} from 'react'
import styles from './Header.module.scss'
import { useNavigate, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faMagnifyingGlass, faUser} from '@fortawesome/free-solid-svg-icons';
import { UserContext, CartShoppingContext} from '../../Context/GlobalContext'
import { useTranslation } from 'react-i18next';
import Marquee from '../Marquee/Marquee';

export default function Header() {
    const { t, i18n } = useTranslation();
    const [languageFlag, setLanguageFlag] = useState(false);
    const [languageData, setLanguageData] = useState([]);
    const [currentLanguage, setCurrentLanguage] = useState('en')
    const [carShippingNumber, setCarShippingNumber] = useState(0);
    const {userData, setUserData} = useContext(UserContext)
    const {shoppingCartItem} = useContext(CartShoppingContext)
    const supportedLanguages = i18n.options.supportedLngs;
    const supportedLangImg = i18n.options.supportedLangImg;
    const [search, setSearch] = useState('')
    const navigate = useNavigate();

    const HonmeBtn = () => {
        navigate('/')
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }

    const UserProfileBtn = () => {
        navigate('/UserProfile')
    }

    const handleLanguage = () => {
        if(languageFlag)
            setLanguageFlag(false)
        else
            setLanguageFlag(true)
    }

    const handleMouseEnterLanguage = () => {
        setLanguageFlag(true)
    }

    const handleMouseLeaveLanguage = () => {
        setLanguageFlag(false)
    }

    const changeLanguage = (language) => {
        i18n.changeLanguage(language.name);
        setLanguageFlag(false)
        setCurrentLanguage(language)
        // let newUserDataArray = {...userData, language: language}; // 正确使用对象展开操作符
        // setUserData(newUserDataArray);
        localStorage.setItem('language', JSON.stringify(language));
    };

    useEffect(()=>{
        try {
            const TempLanguageArray = supportedLanguages
            .filter(language => language !== "cimode") // 首先过滤掉 "cimode"
            .map((language, index) => ({          // 然后映射剩余的元素
                id: index + 1,
                name: language,
                image: `${process.env.PUBLIC_URL}/img/${supportedLangImg[index]}`
            }));
            setLanguageData(TempLanguageArray)
            const languageData = localStorage.getItem('language');
            if(languageData && typeof JSON.parse(languageData) === 'object')
                changeLanguage(JSON.parse(languageData));
            else {
                changeLanguage(TempLanguageArray[0])
            }
        } catch(e) {
            changeLanguage(supportedLanguages[0])
        }
    },[]);

    useEffect(() => {
        let totalNumber = 0;
        shoppingCartItem.map(item => totalNumber += item.number)
        setCarShippingNumber(totalNumber)
    },[shoppingCartItem])

    return (
        <div className={styles.main}>
            <img alt="Logo" className={styles.logo} src={process.env.PUBLIC_URL + '/img/icon_logo.png'} onClick={HonmeBtn}/>
            <div className={styles.title} onClick={HonmeBtn}>
                G-Shop
            </div>
            <div className={styles.search}>
                <input type="text" placeholder="search" value={search} onChange={handleSearchChange} tabIndex={0}/>
                <div className={styles.search_icon}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
            </div>
            <Marquee text="今年五月，我們向所有母親致敬，邀請您參與我們的母親節特別慶祝活動。 在這一天，讓我們一起表達對母親無限的愛與尊敬!                             熱愛電競的你，準備好迎接最令人興奮的購物盛宴了嗎？ 我們特別為電競愛好者舉辦的【極速電競嘉年華】，帶來了從未有過的超值優惠和獨家活動！ " />
            <div className={styles.rightItem}>
                <div className={styles.userProfile} onClick={UserProfileBtn}>
                    <div className={styles.icon}>
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                    <div className={styles.text}>
                        <div className={styles.userName}>{t('Hi')},</div>
                        <div className={styles.userName}>{t(userData.name)}</div>
                    </div>
                </div>
                <div className={styles.global} onClick={handleLanguage} onMouseEnter={handleMouseEnterLanguage} onMouseLeave={handleMouseLeaveLanguage}>
                    <img alt="language" src={currentLanguage.image}/>
                    {
                        languageFlag &&
                        <div className={styles.languageList} >
                            {
                                languageData.map(languageItem => (
                                    languageItem.image &&
                                    <div className={styles.languageItem} key={languageItem.id} onClick={() => changeLanguage(languageItem)}>
                                        <div className={styles.languageImg}>
                                            <img alt={languageItem.name} src={languageItem.image}/>
                                        </div>
                                        <div className={styles.languageDsc}>
                                            {t(languageItem.name)}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
                <Link className={styles.carshopping} to={'/ShoppingCart'}>
                    {
                        (carShippingNumber > 0) && 
                        <div className={styles.carShippingNumber}>{carShippingNumber}</div>
                    }
                    <FontAwesomeIcon icon={faCartShopping} />
                </Link>
            </div>
        </div>
    )
}
