import React, { useContext, useState, useEffect} from 'react'
import styles from './QuantityBtn.module.scss'
import { CartShoppingContext} from '../../Context/GlobalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';

export default function QuantityBtn({ProductDetail, className}) {
    const {shoppingCartItem, setShoppingCartItem} = useContext(CartShoppingContext)
    const [productIndex, setProductIndex] = useState(shoppingCartItem.findIndex(x => x.id === ProductDetail.id))
    const [productNumber, setProductNumber] = useState(
        (productIndex === -1 || shoppingCartItem[productIndex] == undefined) ? 0 : shoppingCartItem[productIndex].number
    )

    const handleSubtract  = () => {
        if(productIndex === -1 || shoppingCartItem[productIndex].number === 0)
            return;
        const newshoppingCartItem = [...shoppingCartItem]
        newshoppingCartItem[productIndex].number--;
        if(newshoppingCartItem[productIndex].number === 0)
            newshoppingCartItem.splice(productIndex,1)
        setShoppingCartItem(newshoppingCartItem)
        setProductNumber(productNumber-1)
    }

    const handleAdd = () => {
        if(productIndex === -1)
            setShoppingCartItem([{ ...ProductDetail, number: 1 }, ...shoppingCartItem]);
        else {
            const newshoppingCartItem = [...shoppingCartItem]
            newshoppingCartItem[productIndex].number++;
            setShoppingCartItem(newshoppingCartItem)
        }
        setProductNumber(productNumber+1)
    }

    useEffect(() => {
        setProductIndex(shoppingCartItem.findIndex(x => x.id === ProductDetail.id));
    },[shoppingCartItem])

    return (
        <div className={`${styles.main} ${className}`}>
            <div className={styles.button} onClick={handleSubtract}>
                <FontAwesomeIcon icon={faMinus} />
            </div>
            <span className={styles.number}>{productNumber}</span>
            <div className={styles.button} onClick={handleAdd}>
                <FontAwesomeIcon icon={faPlus} />
            </div>
        </div>
    )
}
