import React, {useContext} from 'react'
import styles from './CategortyListTitle.module.scss'
import { useLocation, useNavigate, Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CategoryContext} from '../../Context/GlobalContext'


export default function CategortyListTitle() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const {categoryList} = useContext(CategoryContext)

    const changeCategoryBtn = (categoryItem) => {
        navigate(`/ProductList/${categoryItem.name}`, { state: categoryItem });
    }

    return (
        <div className={styles['main-title']}>
            {
                categoryList.map(category => (
                    <div className={styles.title} onClick={() => changeCategoryBtn(category)} key={category.id}>{t(category.name)}</div>
                ))
            }
        </div>
    )
}
