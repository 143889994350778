import React, { useState, useEffect, useRef} from 'react';
import styles from './Dropdown.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

export default function CustomDropdown({ options, className, onSelect }) {
    const [selectedValue, setSelectedValue] = useState(options.length > 0 ? options[0].value : "");
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleSelect = (value) => {
        setSelectedValue(value);
        setIsOpen(false);
        if (onSelect) {
            onSelect(value);
        }
    };

    const handleClickOutside = (event) => {
        /**
         * dropdownRef.current 确保下拉菜单的引用存在。
         * !dropdownRef.current.contains(event.target) 检查点击的元素是否不在下拉菜单内部。
         */
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        setSelectedValue(options.length > 0 ? options[0].value : "")
        if(options.length > 0)
            onSelect(options[0].value)
    },[options])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`${styles.dropdown} ${className}`} ref={dropdownRef}>
            <div className={styles.selected} onClick={() => setIsOpen(!isOpen)}>
                {selectedValue || 'Choose an option'}
                {
                    (!isOpen) ?
                    <FontAwesomeIcon className={styles.arrow} icon={faChevronDown} />
                    :
                    <FontAwesomeIcon className={styles.arrow} icon={faChevronUp} />
                }
            </div>
            {isOpen && (
                <div className={styles.options}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={styles.optionItem}
                            onClick={() => handleSelect(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
