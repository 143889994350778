import React, {useEffect, useContext, useState} from 'react'
import styles from './PorductList.module.scss'
import { useLocation, useNavigate, Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CartShoppingContext, ProductContext} from '../../Context/GlobalContext'
import Footer from '../../Component/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import CategortyListTitle from '../../Component/CategortyListTitle/CategortyListTitle';
import AlertDialog from '../../Component/AlertDialog/AlertDialog';

export default function PorductList({avc}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const categoryItem = location.state;
    const {shoppingCartItem, setShoppingCartItem} = useContext(CartShoppingContext)
    const {productList, setProductList} = useContext(ProductContext)
    const [showDialog, setShowDialog] = useState(false);

    const ProdurctDetail = (productItem) => {
        navigate(`/ProductDetail/${productItem.id}`, {state:productItem})
    }

    const CartShoppingBtn = (event, productItem) => {
        event.stopPropagation();  // 阻止事件冒泡
        let ProductIndex = shoppingCartItem.findIndex(item => item.id == productItem.id)
        if(ProductIndex == -1) {
            const newShoppingCartItem = {
                ...productItem,
                // shoppingIndex: shoppingCartItem.length, // 这里使用数组长度作为索引
                number: 1
            };
            setShoppingCartItem([newShoppingCartItem, ...shoppingCartItem])
        } else {
            const newShoppingCartItem = [...shoppingCartItem]
            newShoppingCartItem[ProductIndex].number++;
            setShoppingCartItem(newShoppingCartItem)
        }
        setShowDialog(true);
    }

    const handleCloseDialog = () => {
        setShowDialog(false);
    }

    useEffect(() => {
        if(location.state == null)
            navigate('/')
    },[])

    useEffect(() => {
        console.log(shoppingCartItem)
    },[shoppingCartItem])

    return (
        <>
            <AlertDialog show={showDialog} onClose={handleCloseDialog}>
                <span className={styles.dialogContent}>{t('Add Cart Dsc')}</span>
            </AlertDialog>
            <div className={styles.main}>
                <CategortyListTitle />
                <div className={styles['main-content']}>
                    <div className={styles['product-content']}>
                        {
                            productList
                                .filter(productItem => productItem.productType === categoryItem.id)
                                .map(productItem => (
                                <div className={styles.product} key={productItem.id}>
                                    <div className={styles['product-detail']} onClick={() => ProdurctDetail(productItem)}>
                                        <div className={styles['product-detail-image']}>
                                            <img src={`${process.env.PUBLIC_URL}/img/product/${productItem.productImg}`} />
                                        </div>
                                        <span className={styles['product-detail-name']}>{productItem.productName}</span>
                                        <span className={styles['product-detail-label']}></span>
                                        <div className={styles['product-detail-price']}>
                                            <span>${productItem.productPrice}</span>
                                            <div className={styles.CartShopping} onClick={(event) => CartShoppingBtn(event,productItem)}>
                                                <FontAwesomeIcon icon={faCartShopping} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}
