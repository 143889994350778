import React, {useState, useEffect, useContext} from 'react'
import styles from './ProductDetail.module.scss'
import { useLocation, useNavigate, Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CartShoppingContext } from '../../Context/GlobalContext'
import CategortyListTitle from '../../Component/CategortyListTitle/CategortyListTitle';
import Footer from '../../Component/Footer/Footer';
import QuantityBtn from '../../Component/QuantityBtn/QuantityBtn';
import Dropdown from '../../Component/Dropdown/Dropdown';

export default function ProductDetail() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [productDetail, setProductDetail] = useState(location.state)
    const [inventory, setInventory] = useState([])
    const [selectValue, setSelectValue] = useState('')
    const {shoppingCartItem, setShoppingCartItem} = useContext(CartShoppingContext)

    useEffect(() => {
        const count = Math.min(productDetail.inventory, 20);
        const TempArray = Array.from({ length: count }, (_, i) => ({
            label: i + 1,
            value: i + 1
        }));
        setInventory(TempArray);
    }, []);

    /**收到下拉選單的值 */
    const handleDropdownSelect = (value) => {
        setSelectValue(value);
    }

    const addCartBtn = () => {
        // const productIndex = shoppingCartItem.findIndex(x => x.id === productDetail.id)
        // if(productIndex === -1) {
        //     const newTempArray = [{...productDetail, number: selectValue}, ...shoppingCartItem]
        //     setShoppingCartItem(newTempArray)
        // } else {
        //     const newShoppingCartItem = [...shoppingCartItem]
        //     newShoppingCartItem[productIndex].number = selectValue;
        //     setShoppingCartItem(newShoppingCartItem)
        // }
        setShoppingCartItem(prevItems => {
            const productIndex = prevItems.findIndex(x => x.id === productDetail.id);
            if (productIndex === -1)
                return [{ ...productDetail, number: selectValue }, ...prevItems];
            else {
                return prevItems.map((item, index) =>
                    index === productIndex ? { ...item, number: selectValue } : item
                );
            }
        });
    }

    const buyNowBtn = () => {
        setShoppingCartItem(prevItems => {
            const productIndex = prevItems.findIndex(x => x.id === productDetail.id);
            if (productIndex === -1)
                return [{ ...productDetail, number: selectValue }, ...prevItems];
            else {
                return prevItems.map((item, index) =>
                    index === productIndex ? { ...item, number: selectValue } : item
                );
            }
        });
        navigate('/ShoppingCart')
    }

    return (
        <div className={styles.main}>
            <CategortyListTitle />
            <div className={styles['main-content']}>
                <div className={`${styles.productContent} ${styles.productTitle}`}>
                    <div className={styles['product-img']}>
                        <img alt="Product Image" src={`${process.env.PUBLIC_URL}/img/product/${productDetail.productImg}`}></img>
                    </div>
                    <div className={styles.productNav}>
                        <label className={styles.productName}>{productDetail.productName}</label>
                        <label className={styles.productPrice}>${productDetail.productPrice}</label>
                        <div className={styles['productNav-item']}>
                            {/* <QuantityBtn ProductDetail={productDetail} className={styles.quanity}/> */}
                            <Dropdown options={inventory} className={styles.inventory} onSelect={handleDropdownSelect}/>
                            <div className={styles.buyList}>
                                <button onClick={addCartBtn}>{t('Add Cart')}</button>
                                <button style={{backgroundColor:'#fcd200'}} onClick={buyNowBtn}>{t('Buy Now')}</button>
                            </div>
                            </div>
                    </div>
                </div>
                <div className={`${styles.productContent} ${styles.productBody}`}></div>
            </div>
            <Footer />
        </div>
    )
}
